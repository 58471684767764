import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_MIXCALCO = process.env.REACT_APP_AREAS_MIXCALCO;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_ANTICIPOS = process.env.REACT_APP_URL_ANTICIPOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clienteNombre, setClienteNombre] = useState("VENTA MOSTRADOR");
  const [selectedCliente, setSelectedCliente] = useState("650a334ab99f0b3555a8cf16");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorNombre, setSelectedColaboradorNombre] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [cliente, setCliente] = useState("");
  const [departamentoCliente, setDepartamentoCliente] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [paqueteria, setPaqueteria] = useState("");
  const [idPaqueteria, setIdPaqueteria] = useState("650b273bc9855e3f883e8f5d");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(AREAS_CEDIS);
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);
  
  const [listaPrecios, setListaPrecios] = useState(1);

  const [vistaAnticipo, setVistaAnticipo] = useState(false);
  const [importe, setImporte] = useState(0);
  const [formaPago, setFormaPago] = useState("");
  const [bancos, setBancos] = useState([]);
  const [selectedBanco, setSelectedBanco] = useState([]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      articulosNombre: "",
      observacionesArticulo: "NA",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
        let result = allArticulos.map((a) => a._id);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CLIENTES}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_PAQUETERIAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allPaqueterias = res.data;
          setPaqueterias(allPaqueterias);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

        axios
          .get(`${URL_INVENTARIOS}DisponibleGENERAL`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allDisponible = response.data;
            setInventarios(allDisponible);
          })
          .catch((err) => {
            console.log(err);
          })
          axios
          .get(URL_BANCOS, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allBancos = response.data;
            setBancos(allBancos);
          })
          .catch((err) => {
            console.log(err);
          });
          if(selectedCliente == "650a334ab99f0b3555a8cf16"){
            axios
            .get(`${URL_CLIENTES}/${selectedCliente}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((res) => {
              const allCliente = res.data;
              setCliente(allCliente);
              setDescuento(allCliente.descuento);
              setListaPrecios(allCliente.listaPrecios);
              setSelectedColaborador(allCliente.vendedor[0]._id);
              setSelectedColaboradorNombre(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
              setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
                })
                .catch((err) => {
                  console.log(err);
                });
          }

  }, [selectedArea]);

  const savePedido = async (event) => {
    event.preventDefault();
     const preciosValidos = cambiarPrecio || user.precio_menor || inputFields.every(a => a.precio >= a.precioOriginal - 51)
   
     if (!preciosValidos) {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "No puedes vender $50 menos del precio original",
         showConfirmButton: false,
       });
       return;
     }
  
    if (!selectedCliente || !selectedArea) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Faltan datos, favor de revisar",
      });
      return;
    }
  
    setValidaBoton(false);
    toggleProgreso();
  
    try {
      const pedidoData = {
        fecha,
        clientes: selectedCliente,
        departamentosClientes: departamentoCliente,
        colaboradores: selectedColaborador,
        total_general: parseFloat(total_general).toFixed(2),
        observaciones,
        total_cantidad,
        paqueterias: idPaqueteria,
        areas: selectedArea,
        comisionTDC,
        comisionFlete,
        subTotal: parseFloat(subTotal).toFixed(2),
        iva: parseFloat(iva).toFixed(2),
        impuestos: selectedImpuesto,
        surtido: "No",
        autorizadoApartado: "Si",
        autorizadoSurtido: "Si",
        editado: "No",
        fechaModificado: fecha,
        creado: user.id,
        fecha_estimada: fecha,
        fecha_entrega: fecha,
        entregaInsumos: "No",
        descuento,
        anticipo: vistaAnticipo ? "Si" : "No",
      };
  
      const pedidoResponse = await axios.post(URL_PEDIDOS, pedidoData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      });
  
      const pedidoId = pedidoResponse.data._id;
      const numeroPedido = pedidoResponse.data.idPedido;
  
      await Promise.all(
        inputFields.map(async (a) => {
          if (a.articulos && a.cantidad) {
            await axios.post(
              URL_ARTICULOS_PEDIDO,
              {
                pedidos: pedidoId,
                articulos: a.articulos,
                clientes: selectedCliente,
                cantidad: parseFloat(a.cantidad),
                surtido: 0,
                pendiente_surtir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio).toFixed(2),
                total: parseFloat(a.total).toFixed(2),
                pack: "NA",
                autorizadoSurtido: "Si",
                autorizadoApartado: "Si",
                observaciones: a.observacionesArticulo,
                fecha,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          }
        })
      );
  
      if (vistaAnticipo) {
        await axios.post(
          URL_ANTICIPOS,
          {
            fecha,
            importe,
            formaPago,
            bancos: selectedBanco,
            clientes: selectedCliente,
            utilizado: 0,
            saldo: importe,
            pedidos: pedidoId,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
      }
  
      PDFOC(numeroPedido);
  
      await axios.post(
        URL_LOGS,
        {
          tipo: "Crear Pedido",
          detalle: `${selectedCliente} ${total_general}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
  
      Swal.fire("Good job!", "Creado con éxito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
        footer: error.response?.data || "",
      });
      console.error(error);
      setValidaBoton(true);
    }
  };
  

function PDFOC(
    numero
  ) {
    if(!vistaAnticipo){
      let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
      let TK = totalKG.reduce((t, total, index) => t + total, 0);

      let importes = inputFields.map((c) => parseFloat(c.total));
      let TG = importes.reduce((t, total, index) => t + total, 0);

      let TC = inputFields.map((c) => parseFloat(c.cajas));
      let TTC = TC.reduce((t, total, index) => t + total, 0);

      let iva_tabla = (TG * selectedImpuesto) / 100;

      let temp1 = (TG * descuento) / 100;

      const data = inputFields
      .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
      .map((a) => {
        if(a.cantidad > 0){
        // totalParcial = a.cantidad * a.precio;
        // subTotalTabla = subTotalTabla + totalParcial
        // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
        return [
          a.articulosNombre,
          new Intl.NumberFormat("en-US").format(a.cajas),
          new Intl.NumberFormat("en-US").format(a.piezasPorCaja),
          new Intl.NumberFormat("en-US").format(a.cantidad),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.precio),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total),
        ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF({compressPdf: true});
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 8, 15, 18, 5);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 10, 21, 30, 30);

      var img3 = new Image();
      img3.src = process.env.PUBLIC_URL + "logoWA.png";
      doc.addImage(img3, "png", 140, 40, 5, 5);
      var img4 = new Image();
      img4.src = process.env.PUBLIC_URL + "logoFB.png";
      doc.addImage(img4, "png", 140, 47, 5, 5);
      var img5 = new Image();
      img5.src = process.env.PUBLIC_URL + "logoIG.png";
      doc.addImage(img5, "png", 140, 54, 5, 5);

      let alto = 5

      doc.setFontSize(14);
      doc.text(`HOLLANDER BH`,50, alto + 15);
      doc.setFontSize(10);
      doc.text(`RFC HBH190522NDA`, 50, alto + 20);
      doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 50, alto + 25);
      doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 50, alto + 30);
      doc.text(`CMX, CP: 06020`, 50, alto + 35);
      doc.text(`Tel: 55-5542-5297`, 50, alto + 40);
      doc.text(`www.hollandermexico.com`, 50, alto + 45);

      doc.setFontSize(10);
      doc.text(`Fecha:`, 140, alto + 15);
      doc.text(`${fecha}`, 160, alto + 15);
      doc.text(`Pedido:`, 140, alto + 20);
      doc.text(`${numero}`, 160, alto + 20);
      // doc.text(`Paqueteria:`, 140, alto + 25);
      // doc.text(`${paqueteria}`, 160, alto + 25);
      doc.text(`Ejecutivo:`, 140, alto + 30);
      doc.text(`${selectedColaboradorNombre}`, 160, alto + 30);

      doc.text(`5614826826`, 147, alto + 39);
      doc.text(`@hollandermexico`, 147, alto + 45);
      doc.text(`@hollandermexico`, 147, alto + 52);


      doc.text(`Cliente: ${clienteNombre}`, 10, alto + 55);

      doc.setFontSize(14);
      doc.text(`PEDIDO`, 95, alto + 62);
      
      doc.autoTable({
        head: [
          [
            "Articulo",
            // "Observaciones",
            "Cajas",
            "PPC",
            "Cant.",
            // "Surt.",
            "Precio",
            "Total",
          ],
        ],
        body: dataPDFLimpia,
        styles: {
          fontSize: 10,
          overflow: "linebreak",
          columnWidth: "wrap",
          // font: 'arial',
          overflowColumns: "linebreak",
        },
        headStyles: {
          fillColor: [3, 165, 252],
          lineWidth: 0
        },
        margin: { left: 3, right: 5 },
        startY: 70,
        theme:"plain",
        foot: [
          [
            // "",
            "",
            new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
            "",
            new Intl.NumberFormat("en-US").format(TK) + " Piezas",
            "Sub Total",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(TG),
          ],
          [
            // "",
            "",
            "",
            "",
            "",
            `IVA`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(iva_tabla),
          ],
          [
            // "",
            "",
            "",
            "",
            "",
            "Comision TDC",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionTDC),
          ],
          [
            // "",
            "",
            "",
            "",
            "",
            "Flete",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionFlete),
          ],
          [
            // "",
            "",
            "",
            "",
            "",
            `Desc ${descuento}%`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(-temp1),
          ],
          [
            // "",
            "",
            "",
            "",
            "",
            "Total",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(total_general),
          ],
        ],
        showFoot: "lastPage",
      });

      doc.setFontSize(10);
      var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
      var lines2 = doc.splitTextToSize(`Para el apartado de la mercancía se requiere un 30% de anticipo, el cual se utilizara exclusivamente al liquidar la nota.`, 200);
      var lines3 = doc.splitTextToSize(`Firma`, 200);
      var lines4 = doc.splitTextToSize(`___________________`, 200);
      doc.text(10, doc.autoTable.previous.finalY + 10, lines);
      doc.text(10, doc.autoTable.previous.finalY + 15, lines2);
      doc.text(102, doc.autoTable.previous.finalY + 40, lines3);
      doc.text(90, doc.autoTable.previous.finalY + 33, lines4);

      // doc.save(`Pedido-${numero}.pdf`);
      doc.autoPrint();
      window.open(doc.output("bloburl"), "_blank");
    }else{
      let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = inputFields.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = inputFields.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * selectedImpuesto) / 100;

        let temp1 = (TG * descuento) / 100;

        const data = inputFields
        .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
        .map((a) => {
          if(a.cantidad > 0){
          // totalParcial = a.cantidad * a.precio;
          // subTotalTabla = subTotalTabla + totalParcial
          // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
          return [
            a.articulosNombre,
            new Intl.NumberFormat("en-US").format(a.cajas),
            new Intl.NumberFormat("en-US").format(a.piezasPorCaja),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
          }
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 15, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 21, 30, 30);

        var img3 = new Image();
        img3.src = process.env.PUBLIC_URL + "logoWA.png";
        doc.addImage(img3, "png", 140, 40, 5, 5);
        var img4 = new Image();
        img4.src = process.env.PUBLIC_URL + "logoFB.png";
        doc.addImage(img4, "png", 140, 47, 5, 5);
        var img5 = new Image();
        img5.src = process.env.PUBLIC_URL + "logoIG.png";
        doc.addImage(img5, "png", 140, 54, 5, 5);

        let alto = 5

        doc.setFontSize(14);
        doc.text(`HOLLANDER BH`,50, alto + 15);
        doc.setFontSize(10);
        doc.text(`RFC HBH190522NDA`, 50, alto + 20);
        doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 50, alto + 25);
        doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 50, alto + 30);
        doc.text(`CMX, CP: 06020`, 50, alto + 35);
        doc.text(`Tel: 55-5542-5297`, 50, alto + 40);
        doc.text(`www.hollandermexico.com`, 50, alto + 45);

        doc.setFontSize(10);
        doc.text(`Fecha:`, 140, alto + 15);
        doc.text(`${fecha}`, 160, alto + 15);
        doc.text(`Pedido:`, 140, alto + 20);
        doc.text(`${numero}`, 160, alto + 20);
        // doc.text(`Paqueteria:`, 140, alto + 25);
        // doc.text(`${paqueteria}`, 160, alto + 25);
        doc.text(`Ejecutivo:`, 140, alto + 30);
        doc.text(`${selectedColaboradorNombre}`, 160, alto + 30);

        doc.text(`5614826826`, 147, alto + 39);
        doc.text(`@hollandermexico`, 147, alto + 45);
        doc.text(`@hollandermexico`, 147, alto + 52);


        doc.text(`Cliente: ${clienteNombre}`, 10, alto + 55);

        doc.setFontSize(14);
        doc.text(`PEDIDO`, 95, alto + 62);
        
        doc.autoTable({
          head: [
            [
              "Articulo",
              // "Observaciones",
              "Cajas",
              "PPC",
              "Cant.",
              // "Surt.",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: [3, 165, 252],
            lineWidth: 0
          },
          margin: { left: 3, right: 5 },
          startY: 70,
          theme:"plain",
          foot: [
            [
              // "",
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              "",
              new Intl.NumberFormat("en-US").format(TK) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(TG),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `IVA`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva_tabla),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `Desc ${descuento}%`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(-temp1),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `Anticipo - ${formaPago}`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(importe),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general - importe),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        var lines2 = doc.splitTextToSize(`Para el apartado de la mercancía se requiere un 30% de anticipo, el cual se utilizara exclusivamente al liquidar la nota.`, 200);
        var lines3 = doc.splitTextToSize(`Firma`, 200);
        var lines4 = doc.splitTextToSize(`___________________`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        doc.text(10, doc.autoTable.previous.finalY + 15, lines2);
        doc.text(102, doc.autoTable.previous.finalY + 40, lines3);
        doc.text(90, doc.autoTable.previous.finalY + 33, lines4);

        // doc.save(`Pedido-${numero}.pdf`);
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");
    }
        

  }

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        observacionesArticulo: "NA",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  };

  const handleChangeInputObservaciones = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.observacionesArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = (
          parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)
        ).toFixed(2);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    if (
      inputFields.filter((e) => e.articulos == selectedArticulo._id).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulosNombre = "";
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == selectedArticulo._id) {
          let idArticulo = selectedArticulo._id;
          let piezasPorCaja = a.piezasPorCaja;
          let precio
            if(listaPrecios == 1){
            precio = a.lista1
          } else if(listaPrecios == 2){
            precio = a.lista2
          } else if(listaPrecios == 3){
            precio = a.lista3
          }else if(listaPrecios == 4){
            precio = a.lista4
          }
          let precioOriginal
            if(listaPrecios == 1){
            precioOriginal = a.lista1
          } else if(listaPrecios == 2){
            precioOriginal = a.lista2
          } else if(listaPrecios == 3){
            precioOriginal = a.lista3
          }else if(listaPrecios == 4){
            precioOriginal = a.lista4
          }
          let articulosNombre = selectedArticulo.codigo  + " " + selectedArticulo.nombre;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            precioOriginal,
            articulosNombre
          );
        }
      });
    }
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (
        inputFields.filter((e) => e.articulos == event.target.value).length > 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo ya fue capturado!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulosNombre = "";
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      } else {


      if (idsArticulos.includes(event.target.value)) {
        articulos.map((a) => {
          if (a._id == event.target.value) {
            let idArticulo = a._id;
            let piezasPorCaja = a.piezasPorCaja;
            let precio
              if(listaPrecios == 1){
              precio = a.lista1
            } else if(listaPrecios == 2){
              precio = a.lista2
            } else if(listaPrecios == 3){
              precio = a.lista3
            }else if(listaPrecios == 4){
              precio = a.lista4
            }
            let precioOriginal
              if(listaPrecios == 1){
              precioOriginal = a.lista1
            } else if(listaPrecios == 2){
              precioOriginal = a.lista2
            } else if(listaPrecios == 3){
              precioOriginal = a.lista3
            }else if(listaPrecios == 4){
              precioOriginal = a.lista4
            }
            let articulosNombre = a.codigo  + " " + a.nombre;
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              precio,
              precioOriginal,
              articulosNombre
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu catalogo",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulosNombre = "";
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } 
  }
    
    
    else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.precioOriginal = precioOriginal;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp = temp + parseFloat(comisionTDC) + parseFloat(comisionFlete) + parseFloat(iva);

    setTotalGeneral(totTemp);
  }

  const options = articulos.map((option) => {
    const junta = option.codigo + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        observacionesArticulo: "NA",
      },
    ]);

    setSelectedCliente(cliente);

    axios
      .get(`${URL_CLIENTES}/${cliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        setDescuento(allCliente.descuento);
        setClienteNombre(allCliente.nombre_comercial);
        setListaPrecios(allCliente.listaPrecios);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorNombre(allCliente.vendedor[0].nombre + " " + allCliente.vendedor[0].apellido);
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
      })
      .catch((err) => {
        console.log(err);
      });

  
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (e * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  function autorizaCambio() {
    if (password == randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: "dany@sistemify.com",
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>La tienda ${user.nombre} ${user.apellido} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Pedido</h3>
          {/* <Form > */}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  jalaCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                // onChange={(e) => {
                //   setSelectedColaborador(e.target.value)
                // }}
                disabled
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>


            <Col md={1}>
              <Label>Paqueteria</Label>
              <Input
                  className="col-sm-12"
                  type="select"
                  value={idPaqueteria}
                  required
                  onChange={(e) => {
                    setIdPaqueteria(e.target.value);
                  }}
                >
                  <option value="0">Selecciona </option>
                  {paqueterias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (
                      a._id != AREAS_MIXCALCO 
                    ) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            <Col md={1}>
              <Label>Comision TDC</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionTDC}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionTDC(value);
                }}
              />
            </Col>
            <Col md={1}>
              <Label>Flete</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={comisionFlete}
                className={`form-control`}
                onValueChange={(value) => {
                  jalaComisionFlete(value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Sub Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={subTotal}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>IVA</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={iva}
                disabled
                className={`form-control`}
              />
            </Col>
            <Col md={1}>
              <Label>Descuento $</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuentoNumero}
                disabled
              />
            </Col>
            <Col md={1}>
              <Label>Total</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={total_general}
                disabled
                className={`form-control`}
              />
            </Col>

            <Col md={1}>
              <Label>Piezas</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas"
                value={total_cantidad}
                required
                disabled
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
                <Label>Cambio de Precio</Label>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={(e) => toggleCambiaPrecio()}
                >
                  Solicitar
                </Button>
              </Col>
          </Row>
          <br />

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Table borderless>
          <thead>
          <tr>
            <th style={{ maxWidth: "120px" }}>Codigo Qr</th>
            <th>Buscador</th>
            <th>Articulo</th>
            <th style={{ maxWidth: "60px" }}>Disponible</th>
            {/* <th>Observaciones</th> */}
            <th style={{ maxWidth: "60px" }}>Cajas</th>
            <th style={{ maxWidth: "60px" }}>Pzs x Caja</th>
            <th style={{ maxWidth: "60px" }}>Cantidad</th>
            <th style={{ maxWidth: "60px" }}>Precio Original</th>
            <th style={{ maxWidth: "60px" }}>Precio</th>
            <th style={{ maxWidth: "80px" }}>Total</th>
          </tr>
          </thead>
          <tbody>
            {inputFields.map((inputField)=>(
              <tr>
                <td style={{ maxWidth: "120px" }}>
                <Input
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                </td>
                <td>
                <Autocomplete
                    size="small"
                    value={inputField.articulosNombre}
                    onChange={(event, selectedArticulo) => {
                      BuscaArticulo(inputField.id, selectedArticulo);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre}
                      </React.Fragment>
                    )}
                  />
                </td>
                <td>
                <Input
                    name="articulo"
                    type="string"
                    placeholder="Articulo"
                    value={inputField.articulosNombre}
                    disabled
                  />
                </td>
                <td style={{ maxWidth: "60px" }}>
                <Input
                      type="select"
                      disabled
                    >
                      {inventarios
                        .map((a) => {
                          if(inputField.articulos == a.idArticulo){
                          return (
                            <option>
                              {a.disponible}
                            </option>
                          )}
                        })}
                    </Input>
                </td>
                {/* <td>
                <Input
                    name="observaciones"
                    type="text"
                    placeholder="observaciones"
                    value={inputField.observacionesArticulo}
                    required
                    onChange={(event) =>
                      handleChangeInputObservaciones(inputField.id, event)
                    }
                  />
                </td> */}
                <td style={{ maxWidth: "60px" }}>
                <Input
                    name="cajas"
                    type="string"
                    value={inputField.cajas}
                    onChange={(event) =>
                      handleChangeInputCajas(inputField.id, event)
                    }
                  ></Input>
                </td>
                <td style={{ maxWidth: "60px" }}>
                <Input
                    name="piezasPorCaja"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.piezasPorCaja}
                    required
                    disabled
                  />
                </td>
                <td style={{ maxWidth: "60px" }}>
                <Input
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputCantidad(inputField.id, event)
                    }
                  />
                </td>
                <td style={{ maxWidth: "60px" }}>
                <Input
                        // bsSize="sm"
                        name="precioOriginal"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="PrecioOriginal"
                        value={inputField.precioOriginal}
                        disabled
                      />
                </td>
                <td style={{ maxWidth: "60px" }}>
                <Input
                    style={
                      inputField.precioOriginal -50 > inputField.precio
                        ? { backgroundColor: "#ed8c8c" }
                        : null
                    }
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                </td>
                <td style={{ maxWidth: "80px" }}>
                <Input
                    name="total"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="total"
                    value={inputField.total}
                    disabled
                  />
                </td>
                <td>
                <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>

          </Table>
          <br />
          <Row>
          <Col md={3}>
          <Input
              type="checkbox"
              onChange={(event) => setVistaAnticipo(event.currentTarget.checked)}
              checked={vistaAnticipo}
            />
            <h4 className="subMenuTitulo">Deseas dar de alta un Anticipo?</h4>

            </Col>
            {/* </Row> */}
            {vistaAnticipo ? (
              <>
              {/* <Row> */}
              <Col md={2}>
              <Label className="mr-sm-2">Forma de Pago</Label>
              <Input
                type="select"
                value={formaPago}
                onChange={(e) => {
                  setFormaPago(e.target.value);
                }}
              >
                <option value="">Selecciona una Forma de Pago</option>
                <option value="Efectivo">Efectivo</option>
                <option value="Transfer">Transfer</option>
                <option value="TDC">TDC</option>
              </Input>
            </Col>
            <Col md={2}>
                    <Label className="mr-sm-2">Banco</Label>
                    <Input
                      type="select"
                      value={selectedBanco}
                      onChange={(e) => {
                        setSelectedBanco(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Banco</option>
                      {bancos
                        .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.banco} {a.cuenta}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col sm={2}>
                    <Label className="mr-sm-2">Importe</Label>
                    <CurrencyInput
                      intlConfig={{ locale: 'en-US', currency: 'USD' }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={importe}
                      className={`form-control`}
                      onValueChange={(value, name) => setImporte(value)}
                    />
                  </Col>
            </>
            ):undefined}
            </Row>
          {/* </Form> */}
          {/* Termina Tabla Articulos */}
          <br />
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoPedidos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>


          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosCreate;
