import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ANTICIPOS = process.env.REACT_APP_URL_ANTICIPOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_MIXCALCO = process.env.REACT_APP_AREAS_MIXCALCO;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [paqueterias, setPaqueterias] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [paqueteria, setPaqueteria] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedAnticipo, setSelectedAnticipo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [surtido, setSurtido] = useState("");
  const [areaSurtido, setAreaSurtido] = useState("");
  const [nombreClienteEdit, setNombreClienteEdit] = useState("");
  const [fechaModificado, setFechaModificado] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);
  const [selectedAreaEditVieja, setSelectedAreaEditVieja] = useState("");
  const [selectedAreaEdit, setSelectedAreaEdit] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  // const [modalNoDisponible, setModalNoDisponible] = useState(false);
  // const toggleNoDisponible = () => setModalNoDisponible(!modalNoDisponible);
  const [allArticulosAutorizar, setAllArticulosAutorizar] = useState([]);


  
  const [totalTotalCantidad, setTotalTotalCantidad] = useState(0);
  const [totalTotalDinero, setTotalTotalDinero] = useState(0);

  const [status, setStatus] = useState("Abierto");
  const [autorizadoApartado, setAutorizadoApartado] = useState("");
  const [autorizadoSurtido, setAutorizadoSurtido] = useState("");

  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [AbrirEditar, setAbrirEditar] = useState(false);

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      // .get(`${URL_PEDIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        .get(`${URL_PEDIDOS}SinSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idPedido,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              status: a.status,
              autorizadoApartado: a.autorizadoApartado,
              autorizadoSurtido: a.autorizadoSurtido,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              idPaqueteria: a.paqueterias[0]._id,
              paqueteria: a.paqueterias[0].name,
              areaName: a.areas[0].name,
              idArea: a.areas[0]._id,
              surtido: a.surtido,
              codigoClientes: a.clientes[0].codigo,
              calle: a.clientes[0].calle,
              numero_ext: a.clientes[0].numero_ext,
              numero_int: a.clientes[0].numero_int,
              pais: a.clientes[0].pais,
              estado: a.clientes[0].estado,
              ciudad: a.clientes[0].ciudad,
              delegacion: a.clientes[0].delegacion,
              colonia: a.clientes[0].colonia,
              cp: a.clientes[0].cp,
              RFC: a.clientes[0].RFC,
              area: a.areas[0].name,
              fechaModificado: a.fechaModificado,
              telefono: a.clientes[0].telefono,
              editado: a.editado,
              editPedido: a.editPedido,
              autorizoApartado: a.autorizoApartado,
              autorizoSurtido: a.autorizoSurtido,
              descuento: a.descuento,
              anticipo: a.anticipo,
              creado: a.creado[0].nombre + " " + a.creado[0].apellido,
              fechaHora: moment(a.createdAt).utcOffset('-0600').format("DD-MM-YYYY") + " " + moment(a.createdAt).utcOffset('-0600').format("HH:mm:ss"),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_PAQUETERIAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allPaqueterias = res.data;
          setPaqueterias(allPaqueterias);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedAnticipo == 0 || selectedAnticipo == a.anticipo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedAnticipo == 0 || selectedAnticipo == a.anticipo)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (autorizadoApartado == 0 ||
          autorizadoApartado == a.autorizadoApartado) &&
        (autorizadoSurtido == 0 || autorizadoSurtido == a.autorizadoSurtido) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedAnticipo == 0 || selectedAnticipo == a.anticipo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Ordenes de Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idColaboradores,
    idClientes,
    total_cantidad,
    total_general,
    observaciones,
    numero_pedido,
    comisionTDC,
    comisionFlete,
    impuestos,
    idPaqueteria,
    idEdit,
    idArea
  ) {


    setFecha(fecha);
    setSelectedColaboradorEdit(idColaboradores);
    setSelectedClienteEdit(idClientes);
    setTotalCantidad(total_cantidad);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroPedido(numero_pedido);
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);
    setSelectedAreaEditVieja(idArea);
    setSelectedAreaEdit(idArea);

    setPaqueteria(idPaqueteria);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    impuestos,
    comisionFlete,
    comisionTDC,
    idPDFOC,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    autorizadoApartado,
    autorizadoSurtido,
    telefono,
    editado,
    editPedido,
    autorizoApartado,
    autorizoSurtido,
    descuento,
    creado,
    fechaHora,
    anticipo
  ) {
   if(anticipo == "No"){
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " - " + a.articulos[0].nombre,
              observaciones: a.observaciones,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              ppc: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * impuestos) / 100;

        let temp1 = (TG * descuento) / 100;

        const data = dataFinal
        .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
        .map((a) => {
          // totalParcial = a.cantidad * a.precio;
          // subTotalTabla = subTotalTabla + totalParcial
          // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.ppc.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 15, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 21, 30, 30);

        var img3 = new Image();
        img3.src = process.env.PUBLIC_URL + "logoWA.png";
        doc.addImage(img3, "png", 140, 40, 5, 5);
        var img4 = new Image();
        img4.src = process.env.PUBLIC_URL + "logoFB.png";
        doc.addImage(img4, "png", 140, 47, 5, 5);
        var img5 = new Image();
        img5.src = process.env.PUBLIC_URL + "logoIG.png";
        doc.addImage(img5, "png", 140, 54, 5, 5);

        let alto = 5

        doc.setFontSize(14);
        doc.text(`HOLLANDER BH`,50, alto + 15);
        doc.setFontSize(10);
        doc.text(`RFC HBH190522NDA`, 50, alto + 20);
        doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 50, alto + 25);
        doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 50, alto + 30);
        doc.text(`CMX, CP: 06020`, 50, alto + 35);
        doc.text(`Tel: 55-5542-5297`, 50, alto + 40);
        doc.text(`www.hollandermexico.com`, 50, alto + 45);

        doc.setFontSize(10);
        doc.text(`Fecha:`, 140, alto + 15);
        doc.text(`${fechaHora}`, 160, alto + 15);
        doc.text(`Pedido:`, 140, alto + 20);
        doc.text(`${numero}`, 160, alto + 20);
        doc.text(`Paqueteria:`, 140, alto + 25);
        doc.text(`${paqueteria}`, 160, alto + 25);
        doc.text(`Ejecutivo:`, 140, alto + 30);
        doc.text(`${colaboradores}`, 160, alto + 30);

        doc.text(`5614826826`, 147, alto + 39);
        doc.text(`@hollandermexico`, 147, alto + 45);
        doc.text(`@hollandermexico`, 147, alto + 52);


        doc.text(`Cliente: ${clientes}`, 10, alto + 55);

        doc.setFontSize(14);
        doc.text(`PEDIDO`, 95, alto + 62);
        
        doc.autoTable({
          head: [
            [
              "Articulo",
              // "Observaciones",
              "Cajas",
              "PPC",
              "Cant.",
              // "Surt.",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: [3, 165, 252],
            lineWidth: 0
          },
          margin: { left: 3, right: 5 },
          startY: 70,
          theme:"plain",
          foot: [
            [
              // "",
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              "",
              new Intl.NumberFormat("en-US").format(TK) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(TG),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `IVA`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva_tabla),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `Desc ${descuento}%`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(-temp1),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        var lines2 = doc.splitTextToSize(`Para el apartado de la mercancía se requiere un 30% de anticipo, el cual se utilizara exclusivamente al liquidar la nota.`, 200);
        var lines3 = doc.splitTextToSize(`Firma`, 200);
        var lines4 = doc.splitTextToSize(`___________________`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        doc.text(10, doc.autoTable.previous.finalY + 15, lines2);
        doc.text(102, doc.autoTable.previous.finalY + 40, lines3);
        doc.text(90, doc.autoTable.previous.finalY + 33, lines4);

        doc.save(`Pedido-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
    } else{

      await axios
      .get(`${URL_ANTICIPOS}Pedido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }).then(async(dataAnticipo) => {
        // console.log("hola",dataAnticipo.data[0].importe)
     

      await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " - " + a.articulos[0].nombre,
              observaciones: a.observaciones,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              ppc: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * impuestos) / 100;

        let temp1 = (TG * descuento) / 100;

        const data = dataFinal
        .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
        .map((a) => {
          // totalParcial = a.cantidad * a.precio;
          // subTotalTabla = subTotalTabla + totalParcial
          // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.ppc.toFixed(2)),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 15, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 21, 30, 30);

        var img3 = new Image();
        img3.src = process.env.PUBLIC_URL + "logoWA.png";
        doc.addImage(img3, "png", 140, 40, 5, 5);
        var img4 = new Image();
        img4.src = process.env.PUBLIC_URL + "logoFB.png";
        doc.addImage(img4, "png", 140, 47, 5, 5);
        var img5 = new Image();
        img5.src = process.env.PUBLIC_URL + "logoIG.png";
        doc.addImage(img5, "png", 140, 54, 5, 5);

        let alto = 5

        doc.setFontSize(14);
        doc.text(`HOLLANDER BH`,50, alto + 15);
        doc.setFontSize(10);
        doc.text(`RFC HBH190522NDA`, 50, alto + 20);
        doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 50, alto + 25);
        doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 50, alto + 30);
        doc.text(`CMX, CP: 06020`, 50, alto + 35);
        doc.text(`Tel: 55-5542-5297`, 50, alto + 40);
        doc.text(`www.hollandermexico.com`, 50, alto + 45);

        doc.setFontSize(10);
        doc.text(`Fecha:`, 140, alto + 15);
        doc.text(`${fechaHora}`, 160, alto + 15);
        doc.text(`Pedido:`, 140, alto + 20);
        doc.text(`${numero}`, 160, alto + 20);
        doc.text(`Paqueteria:`, 140, alto + 25);
        doc.text(`${paqueteria}`, 160, alto + 25);
        doc.text(`Ejecutivo:`, 140, alto + 30);
        doc.text(`${colaboradores}`, 160, alto + 30);

        doc.text(`5614826826`, 147, alto + 39);
        doc.text(`@hollandermexico`, 147, alto + 45);
        doc.text(`@hollandermexico`, 147, alto + 52);


        doc.text(`Cliente: ${clientes}`, 10, alto + 55);

        doc.setFontSize(14);
        doc.text(`PEDIDO`, 95, alto + 62);
        doc.autoTable({
          head: [
            [
              "Articulo",
              // "Observaciones",
              "Cajas",
              "PPC",
              "Cant.",
              // "Surt.",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: [3, 165, 252],
            lineWidth: 0
          },
          margin: { left: 3, right: 5 },
          startY: 70,
          theme:"plain",
          foot: [
            [
              // "",
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              "",
              new Intl.NumberFormat("en-US").format(TK) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(TG),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `IVA`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva_tabla),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `Desc ${descuento}%`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(-temp1),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              `Anticipo ${dataAnticipo.data[0].formaPago}`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(dataAnticipo.data[0].importe),
            ],
            [
              // "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general - dataAnticipo.data[0].importe),
            ],
          ],
          showFoot: "lastPage",
        });
        
        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        var lines2 = doc.splitTextToSize(`Para el apartado de la mercancía se requiere un 30% de anticipo, el cual se utilizara exclusivamente al liquidar la nota.`, 200);
        var lines3 = doc.splitTextToSize(`Firma`, 200);
        var lines4 = doc.splitTextToSize(`___________________`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        doc.text(10, doc.autoTable.previous.finalY + 15, lines2);
        doc.text(102, doc.autoTable.previous.finalY + 40, lines3);
        doc.text(90, doc.autoTable.previous.finalY + 33, lines4);

        doc.save(`Pedido-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      })

      // Termina anticipo
    }).catch((err) => {
      console.log(err);
      
    })

    }
  }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${URL_PEDIDOS}/${idEdit}`;
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${idEdit}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${idEdit}`;

    if (selectedAreaEditVieja != selectedAreaEdit) {
      Swal.fire({
        title: "Estas seguro?",
        text: "Estas cambiando de Alamacen y se liberará el inventario!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Cambiar de Almacen!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .patch(
              URL_AUTORIZADO,
              {
                // autorizadoApartado: "No",
                // autorizadoSurtido: "No",
                autorizoApartado: user.id,
                observaciones,
                editPedido: user.id,
                paqueterias: paqueteria,
                areas: selectedAreaEdit,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((response) => {
              // axios
              //   .patch(
              //     URL_AUTORIZADO_HIJO,
              //     {
              //       autorizadoApartado: "No",
              //       autorizadoSurtido: "No",
              //     },
              //     {
              //       headers: {
              //         Authorization: `Bearer: ${localStorage.getItem(
              //           "app_token"
              //         )}`,
              //       },
              //     }
              //   )
              //   .then(() => {
              //     axios.post(
              //       URL_LOGS,
              //       {
              //         tipo: "Cambio Alamcen Pedido",
              //         detalle: `${numero_pedido} ${fecha}`,
              //         user: user.id,
              //       },
              //       {
              //         headers: {
              //           Authorization: `Bearer: ${localStorage.getItem(
              //             "app_token"
              //           )}`,
              //         },
              //       }
              //     );
              //     toggleEdit();
              //     Swal.fire("Good job!", "Actualizado con exito", "success");
              //     setTimeout(() => {
              //       window.location.reload();
              //     }, 1000);
              //   })
              //   .catch((error) => {
              //     Swal.fire({
              //       icon: "error",
              //       title: "Oops...",
              //       text: "Something went wrong!",
              //       footer: `${error.response.data}`,
              //     });
              //     console.log(error);
              //   });
                  toggleEdit();
                  Swal.fire("Good job!", "Actualizado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        }
      });
    } else {
      axios
        .all([
          axios.patch(
            URL_PEDIDOS_EDIT,
            {
              observaciones,
              editPedido: user.id,
              paqueterias: paqueteria,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
        ])
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Pedido",
              detalle: `${numero_pedido} ${fecha}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Almacen", field: "areaName", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Anticipo", field: "anticipo", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.areaName.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.anticipo.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search) 
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (selectedAnticipo) {
      computedComments = computedComments.filter((e) =>
        e.anticipo.includes(selectedAnticipo)
      );
    }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }
    if (autorizadoSurtido) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoSurtido.includes(autorizadoSurtido)
      );
    }
    if (autorizadoApartado) {
      computedComments = computedComments.filter((e) =>
        e.autorizadoApartado.includes(autorizadoApartado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    let Inv = computedComments.map((c) => parseFloat(c.total_cantidad));
    let TInv = Inv.reduce((t, inv, index) => t + inv, 0);
    setTotalTotalCantidad(TInv);

    let PorR = computedComments.map((c) => parseFloat(c.total_general));
    let TPorR = PorR.reduce((t, porR, index) => t + porR, 0);
    setTotalTotalDinero(TPorR);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedAnticipo,
    status,
    selectedFechaInicio,
    selectedFechaFin,
    autorizadoApartado,
    autorizadoSurtido,
    selectedArea,
  ]);

  function EditArticulos(
    idOC,
    numero_pedido,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    total_cantidad,
    surtido,
    areaSurtido,
    clientes,
    idClientes,
    fechaModificado
  ) {
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);
    setSurtido(surtido);
    setIva(iva.toFixed(2));
    setTotalGeneralEdit(total_general);
    setTotalCantidadEdit(total_cantidad);
    setSubTotal(subTotal);
    setAreaSurtido(areaSurtido);
    setNombreClienteEdit(clientes);
    setFechaModificado(fechaModificado);
    setSelectedClienteEdit(idClientes);

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);

    // axios
    //   .get(`${URL_INVENTARIOS}DisponibleTraspaso/${areaSurtido}`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allDisponible = response.data;
    //     setInventarios(allDisponible);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              is_active: a.is_active,
              articulos: a.articulos[0]._id,
              codigo: a.articulos[0].codigo  + " - " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              pack: "NA",
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    setAbrirEditar(true);
  }

  const handleChangeInputExistentesPrecio = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.precio = event;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0 ||
      articulosPedido.filter((e) => e.articulos == event.target.value).length >
        0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let idArticulo = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          handleChangeInputExistentesArticulo(id, idArticulo, piezasPorCaja);
        }
      });
    }
  }

  const handleChangeInputExistentesArticulo = (
    id,
    idArticulo,
    piezasPorCaja
  ) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCajasExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja) -
          parseFloat(i.surtido);
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  // const handleChangeInputPack = (id, event) => {
  //   const newInputFields = inputFields.map((i) => {
  //     if (id === i.id) {
  //       i.pack = event.target.value;
  //     }
  //     return i;
  //   });
  //   setInputFields(newInputFields);
  // };

  // const handleChangeInputPackExistentes = (id, event) => {
  //   const newArticulosPedido = articulosPedido.map((i) => {
  //     if (id === i.id) {
  //       i.pack = event.target.value;
  //     }
  //     return i;
  //   });
  //   setArticulosPedido(newArticulosPedido);
  // };
  const handleChangeInputCantidadExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.cantidad = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.pendiente_surtir =
          parseFloat(event.target.value) - parseFloat(i.surtido);
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let KG = articulosPedido.concat(values);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG.toFixed(2));
  };

  const handleChangeInputPrecio = (id, value) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.precio = value;
        let totalParcial = i.cantidad * value;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0 ||
      articulosPedido.filter((e) => e.articulos == event.target.value).length >
        0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let idArticulo = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          handleChangeInputArticulo(id, idArticulo, piezasPorCaja, precio);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, idArticulo, piezasPorCaja, precio) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let activos = articulosPedido.filter((e) => e.is_active == "Si");
    let activos2 = activos.concat(inputFields);
    let cantidadActivos = activos2.map((c) => parseFloat(c.cantidad));
    let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TA);

    let totalActivos = activos2.map((c) => parseFloat(c.total));
    let TG = totalActivos.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG.toFixed(2));

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * TG) / 100 +
        TG +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
    setFechaModificado(endDate);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_general: parseFloat(total_general_edit).toFixed(2),
            total_cantidad: total_cantidad_edit,
            editArticulos: user.id,
            comisionTDC,
            comisionFlete,
            subTotal: parseFloat(subTotal).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos: selectedImpuesto,
            editado: "Si",
            // autorizadoApartado: "No",
            // autorizadoSurtido: "No",
            fechaModificado,
            // paqueterias: paqueteria
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total).toFixed(2),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    autorizadoApartado: "Si",
                    autorizadoSurtido: "Si",
                    pack: "NA",
                    clientes: selectedClienteEdit
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total).toFixed(2),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  // autorizadoApartado: "No",
                  // autorizadoSurtido: "No",
                  pack: "NA",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function desautorizarApartado(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se liberará el inventario!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, liberar el inventario!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoApartado: "No",
              autorizadoSurtido: "No",
              autorizoApartado: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoApartado: "No",
                  autorizadoSurtido: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Desautorizar Apartado",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se libero el inventario",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoApartado = "No";
                    i.autorizadoSurtido = "No";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function autorizarApartado(id, almacen) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${id}`;

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir4/${id}/${almacen}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosAutorizar = response.data;
        setAllArticulosAutorizar(allArticulosAutorizar);

        let cantidadArticulos = allArticulosAutorizar.length;

        allArticulosAutorizar.map((a) => {
          // if (a.cantidadPedido > a.disponible) {
          //   return toggleNoDisponible();
          // } else 
          {
            cantidadArticulos = cantidadArticulos - 1;
            if (cantidadArticulos == 0) {
              Swal.fire({
                title: "Estas seguro?",
                text: "Se va a bloquear el inventario!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, bloquear!",
              }).then((result) => {
                if (result.isConfirmed) {
                  axios
                    .patch(
                      URL_AUTORIZADO,
                      {
                        autorizadoApartado: "Si",
                        autorizoApartado: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then((response) => {
                      axios
                        .patch(
                          URL_AUTORIZADO_HIJO,
                          {
                            autorizadoApartado: "Si",
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          axios.post(
                            URL_LOGS,
                            {
                              tipo: "Autorizar Apartado",
                              detalle: `${numero_pedido} ${fecha}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          );
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Se Aparto",
                            showConfirmButton: false,
                            timer: 2000,
                          });
                          const newComments = comments.map((i) => {
                            if (id === i._id) {
                              i.autorizadoApartado = "Si";
                            }
                            return i;
                          });
                          setComments(newComments);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              });
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function desautorizarSurtido(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoSurtido/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se quitara la autorizacion de Surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quitar autorizacion!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "No",
              autorizoSurtido: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoSurtido: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Desautorizar Surtido",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se quito la autorizacion",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoSurtido = "No";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function autorizarSurtido(id) {
    const URL_AUTORIZADO = `${URL_PEDIDOS}/${id}`;
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoSurtido/${id}`;
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a autorizar Surtir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_AUTORIZADO,
            {
              autorizadoSurtido: "Si",
              autorizoSurtido: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                URL_AUTORIZADO_HIJO,
                {
                  autorizadoSurtido: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Autorizar Surtido",
                    detalle: `${numero_pedido} ${fecha}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Autorizo",
                  showConfirmButton: false,
                  timer: 2000,
                });
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.autorizadoSurtido = "Si";
                  }
                  return i;
                });
                setComments(newComments);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    let tg =  ((parseFloat(e) * subTotal) / 100) + subTotal + comisionTDC + comisionFlete
    setTotalGeneralEdit(tg);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(parseFloat(e));
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(parseFloat(e));
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  function autorizaCambio() {
    if (password == PASSWORD_CAMBIA_PRECIO) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }



  function bajaArticulo(id) {
    const URL_AUTORIZADO_HIJO = `${URL_ARTICULOS_PEDIDO}/autorizadoApartado/${idEdit}`;
    const temp = articulosPedido.map((i) => {
      if (id === i.id) {
        i.is_active = "No";
      }
      return i;
    });

    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de baja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        let activos = temp.filter((e) => e.is_active == "Si");
        let cantidadActivos = activos.map((c) => parseFloat(c.cantidad));
        let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TA);

        let totalActivos = activos.map((c) => parseFloat(c.total));
        let TG = totalActivos.reduce((t, total, index) => t + total, 0);
        setSubTotal(TG);

        let IT;
        if (selectedImpuesto != 0) {
          IT = (selectedImpuesto * TG) / 100;
        } else {
          IT = 0;
        }

        setIva(IT);

        let TGT =
          (selectedImpuesto * TG) / 100 +
          TG +
          parseFloat(comisionTDC) +
          parseFloat(comisionFlete);

        setTotalGeneralEdit(TGT.toFixed(2));

        axios
          .patch(
            `${URL_ARTICULOS_PEDIDO}/${id}`,
            {
              is_active: "No",
              autorizadoApartado: "No",
              autorizadoSurtido: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            //Patch Pedidos
            axios
              .patch(
                `${URL_PEDIDOS}/${idEdit}`,
                {
                  total_cantidad: TA,
                  total_general: TGT,
                  subTotal: TG,
                  iva: IT,
                  // autorizadoApartado: "No",
                  // autorizadoSurtido: "No",
                  autorizoApartado: user.id,
                  fechaModificado: hoy,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .patch(
                    URL_AUTORIZADO_HIJO,
                    {
                      autorizadoApartado: "No",
                      autorizadoSurtido: "No",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    const newAP = articulosPedido.map((i) => {
                      if (id === i.id) {
                        i.is_active = "No";
                      }
                      return i;
                    });
                    setArticulosPedido(newAP);

                    const newPed = comments.map((i) => {
                      if (idEdit === i._id) {
                        i.total_cantidad = TA;
                        i.total_general = TGT;
                        i.subTotal = TG;
                        i.iva = IT;
                        // i.autorizadoApartado = "No";
                        // i.autorizadoSurtido = "No";
                      }
                      return i;
                    });
                    setComments(newPed);

                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Se dio de Baja",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            //Termina Patch Pedidos
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function altaArticulo(id) {
    const temp = articulosPedido.map((i) => {
      if (id === i.id) {
        i.is_active = "Si";
      }
      return i;
    });

    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de Alta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Alta!",
    }).then((result) => {
      if (result.isConfirmed) {
        let activos = temp.filter((e) => e.is_active == "Si");
        let cantidadActivos = activos.map((c) => parseFloat(c.cantidad));
        let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TA);

        let totalActivos = activos.map((c) => parseFloat(c.total));
        let TG = totalActivos.reduce((t, total, index) => t + total, 0);
        setSubTotal(TG);

        let IT;
        if (selectedImpuesto != 0) {
          IT = (selectedImpuesto * TG) / 100;
        } else {
          IT = 0;
        }

        setIva(IT);

        let TGT =
          (selectedImpuesto * TG) / 100 +
          TG +
          parseFloat(comisionTDC) +
          parseFloat(comisionFlete);

        setTotalGeneralEdit(TGT.toFixed(2));

        axios
          .patch(
            `${URL_ARTICULOS_PEDIDO}/${id}`,
            {
              is_active: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            //Patch Pedidos
            axios
              .patch(
                `${URL_PEDIDOS}/${idEdit}`,
                {
                  total_cantidad: TA,
                  total_general: TGT,
                  subTotal: TG,
                  iva: IT,
                  // autorizadoApartado: "No",
                  // autorizadoSurtido: "No",
                  autorizoApartado: user.id,
                  fechaModificado: hoy,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((response) => {
                const newAP = articulosPedido.map((i) => {
                  if (id === i.id) {
                    i.is_active = "Si";
                  }
                  return i;
                });
                setArticulosPedido(newAP);

                const newPed = comments.map((i) => {
                  if (idEdit === i._id) {
                    i.total_cantidad = TA;
                    i.total_general = TGT;
                    i.subTotal = TG;
                    i.iva = IT;
                    // i.autorizadoApartado = "No";
                    // i.autorizadoSurtido = "No";
                  }
                  return i;
                });
                setComments(newPed);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se dio de Alta",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
            //Termina Patch Pedidos
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  //     function desarrollo(){
  //     axios
  //       .get(
  //         `${URL_PEDIDOS}Desarrollo`,
  //         {
  //           headers: {
  //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //           },
  //         }
  //       )      
  //       .then(() => {
  //         Swal.fire("Good job!", "Enviado con exito", "success");
  //       })
  //       .catch((error) => {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
  //           footer: `${error.response.data}`,
  //         });
  //         console.log(error);
  //       });
  // }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          {!AbrirEditar ? (
            <>
              <Row>
                <Col md={8}>
                  {user.pedidos_create ? (
                    <Button
                      size="sm"
                      type="submit"
                      className="btn btn-success"
                      href="/PedidosCreate"
                    >
                      Nuevo Pedido
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      type="submit"
                      className="btn btn-success"
                      disabled
                    >
                      Nuevo Pedido
                    </Button>
                  )}
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pedidos</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pedidos</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>

                <Col md={2}>
                  <Label>Autorizado</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoApartado}
                    required
                    onChange={(e) => {
                      setAutorizadoApartado(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Autorizado Surtir</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={autorizadoSurtido}
                    required
                    onChange={(e) => {
                      setAutorizadoSurtido(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Status</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={status}
                    required
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Abierto">Abierto</option>
                    <option value="Cerrado">Cerrado</option>
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tr>
                  <td></td>
                  <td></td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedCliente}
                      onChange={(e) => {
                        setSelectedCliente(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {clientes
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedColaborador}
                      onChange={(e) => {
                        setSelectedColaborador(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona un Vendedor</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (
                            a._id != AREAS_MIXCALCO 
                          ) {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        })}
                    </Input>
                  </td>

                  <td></td>
                  <td></td>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedAnticipo}
                      onChange={(e) => {
                        setSelectedAnticipo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </td>
                </tr>
                <tbody>
                  {commentsData.map((a) => {
                    if (a.activo == "No") {
                      return (
                        <tr style={{ backgroundColor: "#ed8c8c" }}>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.areaName}</td>
                          <td></td>
                          <td></td>
                          <td align="right"></td>
                          <td>CANCELADO</td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    } else if (a.autorizadoSurtido == "Si") {
                      return (
                        <tr style={{ backgroundColor: "#64e379" }}>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.areaName}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.total_cantidad
                            )}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.total_general)}
                          </td>
                          <td>{a.anticipo}</td>
                          <td>
                            {user.pedidos_create ? (
                              <div>
                                {a.surtido == "No" &&
                                user.pedidos_autorizar &&
                                a.status == "Abierto" ? (
                                  a.autorizadoApartado == "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarApartado(a._id)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-up"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) =>
                                          autorizarApartado(a._id, a.idArea)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-down"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.status == "Abierto" ? (
                                  <Button
                                    size="sm"
                                    className="btn"
                                    color="danger"
                                    onClick={(e) =>
                                      PDFOC(
                                        a.fecha,
                                        a.colaboradores,
                                        a.idClientes,
                                        a.clientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.impuestos,
                                        a.comisionFlete,
                                        a.comisionTDC,
                                        a._id,
                                        a.calle,
                                        a.numero_ext,
                                        a.numero_int,
                                        a.pais,
                                        a.estado,
                                        a.ciudad,
                                        a.delegacion,
                                        a.colonia,
                                        a.cp,
                                        a.RFC,
                                        a.paqueteria,
                                        a.area,
                                        a.codigoClientes,
                                        a.autorizadoApartado,
                                        a.autorizadoSurtido,
                                        a.telefono,
                                        a.editado,
                                        a.editPedido,
                                        a.autorizoApartado,
                                        a.autorizoSurtido,
                                        a.descuento,
                                        a.creado,
                                        a.fechaHora,
                                        a.anticipo
                                      )
                                    }
                                  >
                                    <i class="far fa-file-pdf"></i>
                                  </Button>
                                ) : undefined}

                                {a.surtido == "No" &&
                                user.pedidos_autorizar &&
                                a.status == "Abierto" ? (
                                  a.autorizadoApartado == "Si" ? (
                                    a.autorizadoSurtido == "Si" ? (
                                      <span>
                                        <Button
                                          color="primary"
                                          id="Baja"
                                          onClick={(e) =>
                                            desautorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    ) : (
                                      <span>
                                        <Button
                                          color="danger"
                                          onClick={(e) =>
                                            autorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    )
                                  ) : a.autorizadoSurtido == "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarSurtido(a._id)
                                        }
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) => autorizarSurtido(a._id)}
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {user.editar_ventas && a.surtido == "No" ? (
                                  <>
                                    <StatusMultiple
                                      idStatus={a._id}
                                      status={a.status}
                                      URL_STATUS={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                    <BajaMultiple
                                      idStatus={a._id}
                                      is_active={a.activo}
                                      URL_BAJA={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                  </>
                                ) : undefined}
                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            {a.surtido == "No" ? (
                              a.status == "Abierto" && a.activo == "Si" ? (
                                <div>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaInfo(
                                        a.fecha,
                                        a.idColaboradores,
                                        a.idClientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.idPaqueteria,
                                        a._id,
                                        a.idArea
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      EditArticulos(
                                        a._id,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.subTotal,
                                        a.iva,
                                        a.total_general,
                                        a.total_cantidad,
                                        a.surtido,
                                        a.idArea,
                                        a.clientes,
                                        a.idClientes,
                                        a.fechaModificado
                                      )
                                    }
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>
                                </div>
                              ) : undefined
                            ) : undefined}
                          </td>
                          <td>
                            {user.surtidos_create && user.pedidos_autorizar && user.editar_ventas ? (
                              <>
                                <StatusMultipleSurtido
                                  idStatus={a._id}
                                  status={a.surtido}
                                  URL_STATUS={URL_PEDIDOS}
                                  hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                />
                              </>
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          {/* <td>{a.activo}</td> */}
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.areaName}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.total_cantidad
                            )}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.total_general)}
                          </td>
                          <td>{a.anticipo}</td>
                          <td>
                            {user.pedidos_create ? (
                              <div>
                                {a.surtido == "No" &&
                                user.pedidos_autorizar &&
                                a.status == "Abierto" ? (
                                  a.autorizadoApartado == "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarApartado(a._id)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-up"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) =>
                                          autorizarApartado(a._id, a.idArea)
                                        }
                                        size="sm"
                                      >
                                        <i class="fas fa-thumbs-down"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}

                                {a.status == "Abierto" ? (
                                  <Button
                                    size="sm"
                                    className="btn"
                                    color="danger"
                                    onClick={(e) =>
                                      PDFOC(
                                        a.fecha,
                                        a.colaboradores,
                                        a.idClientes,
                                        a.clientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.impuestos,
                                        a.comisionFlete,
                                        a.comisionTDC,
                                        a._id,
                                        a.calle,
                                        a.numero_ext,
                                        a.numero_int,
                                        a.pais,
                                        a.estado,
                                        a.ciudad,
                                        a.delegacion,
                                        a.colonia,
                                        a.cp,
                                        a.RFC,
                                        a.paqueteria,
                                        a.area,
                                        a.codigoClientes,
                                        a.autorizadoApartado,
                                        a.autorizadoSurtido,
                                        a.telefono,
                                        a.editado,
                                        a.editPedido,
                                        a.autorizoApartado,
                                        a.autorizoSurtido,
                                        a.descuento,
                                        a.creado,
                                        a.fechaHora,
                                        a.anticipo
                                      )
                                    }
                                  >
                                    <i class="far fa-file-pdf"></i>
                                  </Button>
                                ) : undefined}

                                {a.surtido == "No" &&
                                user.pedidos_autorizar &&
                                a.status == "Abierto" ? (
                                  a.autorizadoApartado == "Si" ? (
                                    a.autorizadoSurtido == "Si" ? (
                                      <span>
                                        <Button
                                          color="primary"
                                          id="Baja"
                                          onClick={(e) =>
                                            desautorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    ) : (
                                      <span>
                                        <Button
                                          color="danger"
                                          onClick={(e) =>
                                            autorizarSurtido(a._id)
                                          }
                                          size="sm"
                                        >
                                          <i class="fas fa-truck"></i>
                                        </Button>
                                      </span>
                                    )
                                  ) : a.autorizadoSurtido == "Si" ? (
                                    <span>
                                      <Button
                                        color="primary"
                                        id="Baja"
                                        onClick={(e) =>
                                          desautorizarSurtido(a._id)
                                        }
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  ) : (
                                    <span>
                                      <Button
                                        color="danger"
                                        onClick={(e) => autorizarSurtido(a._id)}
                                        size="sm"
                                        disabled
                                      >
                                        <i class="fas fa-truck"></i>
                                      </Button>
                                    </span>
                                  )
                                ) : undefined}
                               
                                {user.editar_ventas && a.surtido == "No" ? (
                                  <>
                                    <StatusMultiple
                                      idStatus={a._id}
                                      status={a.status}
                                      URL_STATUS={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                    <BajaMultiple
                                      idStatus={a._id}
                                      is_active={a.activo}
                                      URL_BAJA={URL_PEDIDOS}
                                      hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                                    />
                                  </>
                                ) : undefined}
                              </div>
                            ) : undefined}
                          </td>
                          <td>
                            {a.surtido == "No" ? (
                              a.status == "Abierto" && a.activo == "Si" ? (
                                <div>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaInfo(
                                        a.fecha,
                                        a.idColaboradores,
                                        a.idClientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.idPaqueteria,
                                        a._id,
                                        a.idArea
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      EditArticulos(
                                        a._id,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.subTotal,
                                        a.iva,
                                        a.total_general,
                                        a.total_cantidad,
                                        a.surtido,
                                        a.idArea,
                                        a.clientes,
                                        a.idClientes,
                                        a.fechaModificado
                                      )
                                    }
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>
                                </div>
                              ) : undefined
                            ) : undefined}
                          </td>
                          <td>
                            {user.surtidos_create && user.pedidos_autorizar && user.editar_ventas ? (
                              <StatusMultipleSurtido
                                idStatus={a._id}
                                status={a.surtido}
                                URL_STATUS={URL_PEDIDOS}
                                hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                              />
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    }
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                <td className="negrita">TOTAL TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTotalCantidad)}
                </td>
                <td className="negrita" align="right">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalTotalDinero)}
                </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                  </tr>
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          ) : (
            <div className="card container col-md-12">
              <h4>Editar Pedido {numero_pedido}</h4>
              <h4>Cliente: {nombreClienteEdit}</h4>
              <Form onSubmit={savePedido}>
                <Row>
                  <Col md={1}>
                    <Label>TDC</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionTDC}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionTDC(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Flete</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionFlete}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionFlete(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={selectedImpuesto}
                      onChange={(e) => {
                        calculaImpuesto(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Sub Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={subTotal}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>IVA</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={iva}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={total_general_edit}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Piezas</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Piezas"
                      value={total_cantidad_edit}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Label></Label>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={(e) => toggleCambiaPrecio()}
                    >
                      Cambiar Precio <i class="fas fa-dollar-sign"></i>
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulos</Label>
                  </Col>
                  {/* <Col md={1}>
                    <Label className="mr-sm-2">Disponible CEDIS</Label>
                  </Col> */}
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Surtido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Total</Label>
                  </Col>
                </Row>

                {articulosPedido
                .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                .map((ao) => (
                  <div key={ao.id}>
                    <Row>
                      {ao.surtido == 0 ? (
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            onChange={(event) => {
                              BuscaArticuloExistentes(ao.id, event);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>{a.codigo} {a.nombre}</option>
                                );
                              })}
                          </Input>
                        </Col>
                      ) : (
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>{a.codigo}</option>
                                );
                              })}
                          </Input>
                        </Col>
                      )}

                      {/* <Col md={1}>
                        <Input type="select" disabled>
                          {inventarios.map((a) => {
                            if (
                              ao.articulos == a.idArticulo &&
                              ao.pack == a.pack
                            ) {
                              return (
                                <option value={a._id}>{a.disponible}</option>
                              );
                            }
                          })}
                        </Input>
                      </Col> */}

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="cajas"
                          type="string"
                          value={ao.cajas}
                          onChange={(event) =>
                            handleChangeInputCajasExistentes(ao.id, event)
                          }
                        ></Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="piezasPorCaja"
                          type="string"
                          value={ao.piezasPorCaja}
                          disabled
                        ></Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={ao.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidadExistentes(ao.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="surtido"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="surtido"
                          value={ao.surtido}
                          disabled
                        />
                      </Col>

                      {user.cambiaPrecios && ao.surtido == 0 ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputExistentesPrecio(ao.id, value)
                            }
                          />
                        </Col>
                      ) : cambiarPrecio ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputExistentesPrecio(ao.id, value)
                            }
                          />
                        </Col>
                      ) : (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.precio}
                            className={`form-control`}
                            disabled
                          />
                        </Col>
                      )}

                      <Col md={1}>
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "USD" }}
                          prefix="$"
                          name="total"
                          defaultValue={0}
                          decimalsLimit={2}
                          value={ao.total}
                          className={`form-control`}
                          disabled
                        />
                      </Col>
                      {/* <Col md={1}>
                        {ao.is_active == "Si" ? (
                          <Button
                            color="primary"
                            id="Baja"
                            onClick={(e) => {
                              bajaArticulo(ao.id);
                            }}
                            size="sm"
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                        ) : (
                          <Button
                            color="danger"
                            onClick={(e) => {
                              altaArticulo(ao.id);
                            }}
                            size="sm"
                          >
                            <i class="far fa-times-circle"></i>
                          </Button>
                        )}
                      </Col> */}
                    </Row>
                  </div>
                ))}

                {/* Agregar mas articulos */}

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          // bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          required
                          onChange={(event) => {
                            BuscaArticulo(inputField.id, event);
                          }}
                        >
                          <option value="0">Selecciona</option>
                          {articulos
                            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.codigo} {a.nombre}</option>;
                            })}
                        </Input>
                      </Col>

                      {/* <Col md={1}>
                        <Input type="select" disabled>
                          {inventarios.map((a) => {
                            if (
                              inputField.articulos == a.idArticulo &&
                              inputField.pack == a.pack
                            ) {
                              return (
                                <option value={a._id}>{a.disponible}</option>
                              );
                            }
                          })}
                        </Input>
                      </Col> */}

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="cajas"
                          type="string"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCajas(inputField.id, event)
                          }
                        ></Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="piezasPorCaja"
                          type="string"
                          value={inputField.piezasPorCaja}
                          disabled
                        ></Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="surtido"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="0"
                          disabled
                        />
                      </Col>

                      {user.cambiaPrecios ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputPrecio(inputField.id, value)
                            }
                          />
                        </Col>
                      ) : cambiarPrecio ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputPrecio(inputField.id, value)
                            }
                          />
                        </Col>
                      ) : (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            disabled
                          />
                        </Col>
                      )}

                      <Col md={1}>
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "USD" }}
                          prefix="$"
                          name="total"
                          defaultValue={0}
                          decimalsLimit={2}
                          value={inputField.total}
                          className={`form-control`}
                          disabled
                        />
                      </Col>
                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirEditar(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          

            <Col md={3}>
              <Label>Paqueteria</Label>
              <Input
                  className="col-sm-12"
                  type="select"
                  value={paqueteria}
                  required
                  onChange={(e) => {
                    setPaqueteria(e.target.value);
                  }}
                >
                  <option value="0">Selecciona </option>
                  {paqueterias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
            </Col>

            <Col md={3}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedAreaEdit}
                onChange={(e) => {
                  setSelectedAreaEdit(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (
                      a._id != AREAS_MIXCALCO
                    ) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalCambiaPrecio} toggle={toggleCambiaPrecio}>
        <ModalHeader toggle={toggleCambiaPrecio}>
          <h4>Autorizar Cambio de Precio</h4>
        </ModalHeader>
        <ModalBody>
          <Input
            bsSize="sm"
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={(event) => setPassword(event.target.value)}
          />
          <br />
          <Row>
            <Col>
              <Button
                type="submit"
                className="btn btn-success"
                onClick={(e) => autorizaCambio()}
              >
                Autorizar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      {/* <Modal size="sm" isOpen={modalNoDisponible} toggle={toggleNoDisponible}>
        <ModalHeader toggle={toggleNoDisponible}>
          <h4>No hay Inventario Disponible</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Disponible</th>
            </tr>

            {allArticulosAutorizar.map((a) => {
              if (a.disponible < a.cantidadPedido) {
                return (
                  <tr style={{ backgroundColor: "#ed8c8c" }}>
                    <td>{a.articuloNombre}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.cantidadPedido)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.disponible)}
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr>
                    <td>{a.articuloNombre}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.cantidadPedido)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(a.disponible)}
                    </td>
                  </tr>
                );
              }
            })}
          </Table>
        </ModalBody>
      </Modal> */}

      {loader}
    </>
  );
}

export default ListadoPedidos;
